var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContainer',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('PageHeader',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs,"back":-1}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"manual"},[_c('div',{staticClass:"manual__tree"},[(_vm.hasPermission(_vm.Permission.DEPOSITORY_MANUAL_EDIT))?_c('div',[_c('ButtonTextIcon',{staticClass:"mb-3",attrs:{"icon":"$plus","color":"secondary","to":{ name: 'manager.bank.depository.courses.item.files.folder.new', params: { courseID: _vm.courseID, monthID: _vm.monthID } }}},[_vm._v("Добавить папку")])],1):_vm._e(),_c('VDivider',{staticClass:"manual__divider"}),_c('div',{staticClass:"information-section__desktop"},[_c('Vuescroll',{ref:"information"},[_c('DraggableTreeview',{attrs:{"group":"manual"},on:{"input":_vm.handleChangeTree},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'manager.bank.depository.courses.item.files.folder', params: { courseID: _vm.courseID, folderID: item.id, monthID: _vm.monthID } },"replace":true}},[_vm._v(_vm._s(item.title))])]}},(_vm.hasPermission(_vm.Permission.DEPOSITORY_MANUAL_EDIT))?{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [[_c('VMenu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.handleCreate(item.id)}}},[_c('VListItemTitle',[_vm._v("Создать")])],1),_c('VListItem',{attrs:{"disabled":Boolean(item.isSystem)},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},[_c('VListItemTitle',[_vm._v("Удалить")])],1)],1)],1)]]}}:null],null,true),model:{value:(_vm.folders),callback:function ($$v) {_vm.folders=$$v},expression:"folders"}})],1)],1),_c('div',{staticClass:"information-section__mobile"},[_c('ButtonTextIcon',{attrs:{"icon":"$menu","color":"teal darken-4"},on:{"click":function($event){_vm.showMobileManualModal = true}}},[_vm._v("Папки")])],1)],1),_c('div',{staticClass:"manual__view"},[(_vm.isTreePage)?_c('div',{staticClass:"manual__view-empty my-10"},[_c('div',{staticClass:"text-body-2 text-center"},[_vm._v("Выберите папку в дереве")])]):_c('RouterView',{key:_vm.$route.fullPath})],1),_c('Confirmation',{ref:"confirm"}),_c('ManagerMobileFoldersModal',{attrs:{"visible":_vm.showMobileManualModal},on:{"update:visible":function($event){_vm.showMobileManualModal=$event},"create-manual":_vm.handleCreate,"delete-manual":_vm.confirmDelete}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }