





































import { Component, Prop, Mixins } from 'vue-property-decorator'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { KnowledgeTreeResource } from '@/store/types'

@Component({
  components: {
    Confirmation,
  },
})
export default class ManagerMobileFoldersModal extends Mixins(NotifyMixin, PermissionsMixin) {
  @Prop({ required: true })
  private visible!: boolean

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get monthID () {
    return +this.$route.params.monthID
  }

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('close', false)
  }

  private handleChangeTree (tree: KnowledgeTreeResource[]) {
    ManagerDepositoryModule.updateFoldersTree({ courseID: this.courseID, monthID: this.monthID, tree })
      .catch(this.notifyError)
  }

  private get folders(): KnowledgeTreeResource[] {
    return ManagerDepositoryModule.foldersTree.folders
  }

  private set folders(value: KnowledgeTreeResource[]) {
    ManagerDepositoryModule.updateFoldersTree({ courseID: this.courseID, monthID: this.monthID, tree: value })
  }

  private handleCreate (id: number) {
    this.handleClose()
    this.$emit('create-manual', id)
  }

  private confirmDelete (id: number) {
    this.handleClose()
    this.$emit('delete-manual', id)
  }

  private cleanup() {
    this.handleClose()
  }
}
