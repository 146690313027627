


































































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Ref } from 'vue-property-decorator'
import DraggableTreeview from 'vuetify-draggable-treeview'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import ManagerMobileFoldersModal from '@/components/modals/depositories/manager/ManagerMobileFoldersModal.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { KnowledgeTreeResource } from '@/store/types'

@Component({
  components: {
    ButtonTextIcon,
    Confirmation,
    DraggableTreeview,
    ManagerMobileFoldersModal,
  },
})
export default class DepositoryFilesMonth extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private get monthID () {
    return +this.$route.params.monthID
  }

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get course () {
    return ManagerDepositoryModule.course
  }

  private fetchCourse () {
    if (this.course && this.course.value !== +this.courseID || !this.course) {
      ManagerDepositoryModule.fetchCourse(+this.courseID)
        .catch((error) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.bank.depository.courses' })
        })
    }
  }

  private showMobileManualModal = false

  private get foldersTreeMonthID() {
    return ManagerDepositoryModule.foldersTree.monthID
  }

  private folders: KnowledgeTreeResource[] = ManagerDepositoryModule.foldersTree.folders

  private get isTreePage () {
    return this.$route.name === 'manager.bank.depository.courses.item.files.month'
  }

  private get breadcrumbs() {
    if (!this.course) {
      return [{ name: 'База знаний', path: '/manager/bank/depository' }]
    } else {
      return [
        { name: 'База знаний', path: '/manager/bank/depository' },
        { name: this.course.name, path: { name: 'manager.bank.depository.courses.item', params: { courseID: this.courseID.toString() } } },
        { name: 'Учебные файлы', path: { name: 'manager.bank.depository.courses.item.files', params: { courseID: this.courseID.toString() } } },
      ]
    }
  }

  private get title() {
    return ManagerDepositoryModule.trainingFilesMonths.data.find(folder => folder.id === this.monthID)?.title
  }

  private mounted () {
    this.$bus.$on('updateTree', this.updateTree)
    if (!this.course)
      this.fetchCourse()
    if (!this.foldersTreeMonthID || this.foldersTreeMonthID !== +this.monthID)
      this.fetchFoldersTree()
  }

  private destroyed() {
    this.$bus.$off('updateTree', this.updateTree as any)
  }

  private updateTree() {
    this.fetchFoldersTree()
  }

  private fetchFoldersTree() {
    ManagerDepositoryModule.fetchFoldersTree({ courseID: this.courseID, courseFolderID: +this.monthID })
      .then(() => {
        this.folders = ManagerDepositoryModule.foldersTree.folders
      })
  }

  private handleChangeTree (tree: KnowledgeTreeResource[]) {
    ManagerDepositoryModule.updateFoldersTree({ courseID: this.courseID, monthID: this.monthID, tree })
      .catch(this.notifyError)
  }

  private handleCreate (id: number) {
    this.$router.push({
      name: 'manager.bank.depository.courses.item.files.folder.new',
      query: { parentID: id.toString() },
    })
  }

  private handleDelete (id: number) {
    ManagerDepositoryModule.removeFolder({ courseID: this.courseID, courseFolderID: this.monthID, mediaFolderID: id })
      .then(() => {
        this.notifySuccess('Папка удалена.')
        this.$bus.$emit('updateTree')
        if (this.$route.name !== 'manager.bank.depository.courses.item.files.month')
          this.$router.push({
            name: 'manager.bank.depository.courses.item.files.month',
            params: {
              courseID: this.$route.params.courseID,
              monthID: this.$route.params.monthID,
            },
          })
      })
      .catch(this.notifyError)
  }

  private confirmDelete (id: number) {
    this.confirm.open(
      'Подтверждение действия',
      'Вы уверены, что хотите удалить папку?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.handleDelete(id)
      })
      .catch(() => {return})
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Учебные файлы',
    }
  }
}
